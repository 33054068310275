.grid {
  position: relative;
  width: 100%;
  overflow: scroll;

  .grid-item {
    width: $artist-grid-size - 0.5%;
    padding-top: 0.7*$artist-grid-size - 0.5%;
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0.25%;
  }
  .bg {
    position: absolute;
    width: 110%;
    height: 110%; 
    top: -5%; 
    left: -5%; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.15s ease-out;
  }
  .hover {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    box-shadow: inset 0px 0px 20px -2px black;
    cursor: pointer;
    h5 {
      background-color: white;
      position: absolute;
      width: 100%;
      bottom: 0;
      margin: 0;
      padding: 0.5rem;
      text-align: center;
    }
  }
  a:hover, a:focus {
    color: inherit;
  }
  .grid-item.active {
    .bg {
      width: 130%;
      height: 130%;
      top: -15%;
      left: -15%;
    }
    h5 {
      font-weight: 400;
      color: $link-hover-color;
    }
  }
}

.list {
  column-count: 3;
  padding-left: 3rem;
  .list-item {
    margin: 0;
    padding: 0.5rem 0;
    a {
      text-decoration: none;
      &:hover, &:focus {
        color: inherit;
      }
    }
    h5 {
      margin: 0;
    }
    &.active {
      a h5 {
        font-weight: 400;
        color: $link-hover-color;
      }
    }
  }
}

html:not(.touch) {
  .grid {
    .grid-item:hover, .grid-item:focus {
      .bg {
        width: 130%;
        height: 130%;
        top: -15%;
        left: -15%;
      }
      h5 {
        font-weight: 400;
        color: $link-hover-color;
      }
    }
  }
  .list .list-item {
    a:hover, a:focus {
      h5 {
        font-weight: 400;
        color: $link-hover-color;
      }
    }
  }
}
