.tabs {
  width: 100%;
  clear: both;
  margin-top: 3rem;
  height: $tab-header-height;
}

.line {
  width: 100%;
  height: 8px;
  background-color: white;
  box-shadow: 0px -3px 6px -3px rgba(0,0,0,0.7);
  position: relative;
  top: 0px;
  z-index: -1;
}

.tab {
  width: 50%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  h5 {
    margin: 0;
    padding: 1rem;
    display: inline-block;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
    color: $darkgray;
  }
  &:hover, &:focus {
    h5 { color: $link-hover-color;}
  }
  &.active {
    box-shadow: 0 -3px 7px -4px rgba(0,0,0,0.7);
    border-bottom: 2px solid white;
    z-index: 1;
    background-color: white;
    h5 { color: black;}
    &:hover, &:focus {
       h5 { color: inherit;}
    }
  }

}

.tabs-content {
  position: relative;
}

.tab-content {
  position: absolute;
  width: 100%;
  top: 0;
  background-color: white;
  z-index: 0;
  display: none;
  &.active {
    z-index: 2;
    display: block;
  }
}