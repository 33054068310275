* {
  box-sizing: border-box;
}

img {
  user-select: none;
}

#wrapper {
  padding-top: $nav-height;
  position: relative;
}

.clear {
  clear: both;
}

.no-scroll {
  overflow: hidden;
}

.holder {
  width: 100%;
  margin: 2rem auto;
  &.narrow {
     max-width: $narrow-holder-width;
  }
  &.wide {
     max-width: $wide-holder-width;
  }
  &.text {
    max-width: $text-holder-width;
    padding: 0 2*$horizontal-padding;
  }
  &.centered {
    text-align: center;
  }
  &.justified {
    text-align: justify;
  }
  &.padding {
    padding: 0.75rem;
  }
}


.col {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
}



section {
  margin: 1rem auto 3rem auto;
  h4 {
    padding: 1rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 0px 8px -2px darken($subnav-shadow-color,10%);
    color: $darkgray;
    background-color: lighten($subnav-bg-color, 4%);
    text-align: center;
    margin-top: 0;
    font-weight: 200;
  }
}



.nav {
  height: $artist-nav-height;
  background-color: $subnav-bg-color;
  box-shadow: 0px 0px 2px 0px $subnav-shadow-color;
  position: relative;
  top: 2px;
  z-index: 5;
  clear: both;
  .holder.narrow {
    padding: 0 1rem;
    text-align: center;
  }
  .holder, form {
    margin: 0 auto;
    height: $artist-nav-height;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .tabs {
    margin: 0;
    display: inline;
    height: $artist-nav-height;
    vertical-align: middle;
  }

}

.sub-nav {
  position: absolute;
  width: 50px;
  top: 0;
  height: 100%;
  cursor: pointer;
  .arrow {
    width: 50px;
    height: 50px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background-size: 300%;
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  &.prev .arrow {
    background-image: url(/img/icons/left.svg);
  }
  &.next {
    right: 0;
    .arrow {
      background-image: url(/img/icons/right.svg);
    }
  }
  &:hover, &:focus {
    .arrow { background-position: -50px 0; }
  }
  &.disabled {
    .arrow { background-position: -100px 0; }
  }
}

.icon, .icon.tab {
  width: $artist-nav-height;
  height: $artist-nav-height;
  margin-left: 0.2*$artist-nav-height;
  margin-top: 0px; //account for box shadow
  background-color: $input-bg-color;
  text-align: center;
  overflow: visible;
  cursor: pointer;
  box-shadow:  0px 0px 2px 0px $icon-shadow-color;
  img {
    width: 0.7*$artist-nav-height;
    height: 0.7*$artist-nav-height;
    margin: 0 auto;
    display: block;
    border: none;
    padding: 0;
  }
  h6 {
    margin: 0 0.5rem;
    padding: 0; 
  }
  &.active {
    border: none;
    box-shadow:  0px 0px 2px 0px $icon-shadow-color, inset 0 0 0 1px $link-hover-color;
  }
  &:not(.active):hover, &:not(.active):focus {
    box-shadow:  0px 0px 2px 0px $icon-shadow-color, inset 0 0 0 1px $link-hover-color;
    color: $link-hover-color;
    h6 {
      color: $link-hover-color;
    }
  }
}

.button-wrapper {
  text-align: center;
  margin-bottom: 1rem;
  a.button {
    float: none;
    top: auto; left: auto;
  }
  span {
    margin: 0;
    line-height: 1;
  }
}

.button {
  position: relative;
  border-radius: 5%;
  border: 0;
  background-color: white;
  box-shadow: 0px 0px 2px 0px $darkgray;
  padding: 0.12rem 0.25rem;
  cursor: pointer;
  line-height: 1;
  background: linear-gradient(to bottom, white 0%, $input-bg-color 100%);
  img {
    display: inline-block;
    vertical-align: middle;
    width: $nav-height - 20px;
    height: $nav-height - 20px;
  }
  span {
    vertical-align: middle;
  }
  &:not(.disabled):hover, &:not(.disabled):focus {
    box-shadow: inset 0 0 0 1px $link-hover-color, 0px 0px 2px 0px $darkgray;
    outline: none;
    span { color: black;}
  }
  &.disabled {
    color: $input-bg-color;
    cursor: default;
  }
}

.parallax {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: fixed;
  &.small {
    height: 500px;
  }
}








#form-modal {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(100,100,100,0.5);
  top: 0;
  left: 0;
  display: none;
  &.active {
    display: block;
  }
  .close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  form {
    position: absolute;
    background-color: white;
    width: 100%;
    max-width: 500px;
    height: auto;
    max-height: 100%;
    overflow: scroll;
    padding: 2rem 1.5rem;
    left: 50%; top: 50%;
    overflow: scroll;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 0 30px -5px rgba(0,0,0,0.5);
  }
  input, textarea {
    width: 100%;
    padding: 0.5rem;
  }
  p#heading {
    margin-top: 0;
  }
}
