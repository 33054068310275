html.artist {
  #slider-artist-previous .item img {
    cursor: normal;
  }
  .holder.narrow {
    margin-top: 0;
    margin-bottom: 0;
  }
  .tab-content {
    display: block;
    visibility: hidden;
    margin-top: 4rem;
    &.active {
      visibility: visible;
    }
  }
  h1 {
    line-height: 1.3;
  }
}