html.artists {
  height: 100%;
  overflow: hidden;
  
  body {
    height: 100%;
  }

  #wrapper {
    height: 100%;
  }

  .nav .holder.narrow {
    padding: 0 1rem;
    text-align: center;
  }

  #filter {
    height: $artist-nav-height;
    width: 65%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4rem;
    line-height: 1;
  }
  .tabs {
    width: 25%;
    position: relative;
    top: 0;
  }

  .tabs-content {
    height: calc(100% - #{$artist-nav-height});
    margin-top: 0.5rem;
    overflow: scroll;
  }

  .tab-content {
    padding-bottom: 3rem;
  }

  

}


