* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $sans-serif;
  font-weight: 200;
  margin: 0.5rem 0;
}

h1 { 
  font-size: 2.8rem; 
  text-transform: uppercase;
}

h2 { 
  font-size: 2.1rem; 
  line-height: 1.12;
}

h3 {
  font-size: 1.65rem;
  text-transform: uppercase;
  font-weight: 400;
  color: $gray;
}

h4 {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 400;
}

h5 {
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.4;
}

h6 {
  font-size: 1rem;
  //font-style: italic;
  line-height: 1.35;
}

p, a, span {
  font-family: $serif;
  font-size: 1.3rem;
  line-height: 1.5;
}

a {
  color: inherit;
  &:hover, &:focus {
    color: $link-hover-color;
  }
}

.italics {
  font-style: italic;
}

nav {
  #breadcrumbs {
    a, span.subtitle {
      text-decoration: none;
      font-family: $sans-serif;
      font-weight: 200;
      text-transform: uppercase;
      font-size: 1rem;
      color: lighten($darkgray, 10%);
      line-height: 1;
    }
    .current a, .current .subtitle {
      color: black;
    }
  }
}
.button {
  span {
    font-size: 1.2rem;
    font-family: $sans-serif;
    //color: $link-hover-color;
    text-transform: uppercase;
  }
}

#overlay {
  .col a {
    text-decoration: none;
    font-size: 1.5rem;
    //line-height: 2;
  }
}

.icon, .icon.tab {
  h6 {
    font-size: 0.225*$artist-nav-height;
  }
}

html.available-works {
  .icon, .icon.tab {
    h6 {
      font-size: 0.85rem;
    }
  }
}

// PAINTING INFO
.info {
  padding-bottom: 0.5rem;
  p {
    margin: 0; 
    font-size: 0.9rem;
    white-space: normal;
  }
  .code {
    font-family: $sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    color: $gray;
  }
  .artist a {
    font-size: 0.8rem;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: bold;
    font-family: $sans-serif;
    color: $darkgray;
    &:hover, &:focus {
      color: $link-hover-color;
    }
  }
}

.info.inline {
  text-align: center;
  padding-bottom: 0;      
  p {
    display: inline-block;
    line-height: 1;
  }
  .artist a {
    line-height: 1;
  }
  span {
    color: $gray;
    line-height: 1.3;
  }
}
//exhibition info 
.exhibition-info {
  .inner {
    text-align: center;
    h6 { 
      margin-top: 1.1rem;
      color: $darkgray;
      font-weight: lighter;
    }
    p { 
      margin-top: 2rem; 
      color: $darkgray;
    }
  } 
}

#overlay .exhibition-info {
  p { 
    color: $darkgray; 
    margin-bottom: 1.5rem;
  }
  .inner p { margin-top: 1rem; }
}

.review-info {
  h4 {
    font-size: 1.3rem;
  }
  h6 {
    font-size: 1rem;
  }
}

.button-wrapper {
  a {
    font-family: $sans-serif;
    font-size: 1.5rem;
    font-weight: 100; 
    text-decoration: none;
    padding: 0.75rem 1rem;
  }
}

.nav {
  p, span {
    font-family: $sans-serif;
    font-weight: 100;
    font-size: 0.8rem;
    text-transform: uppercase;
    //line-height: 1;
  }
}

a.page {
  font-family: $sans-serif;
  font-weight: 100;
  text-decoration: none;
}

html.contact {
  #wrapper {
    p, a {
      line-height: 1.75;
    }
    p.links a {
      text-decoration: none;
    }
  }

}
