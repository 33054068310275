$serif: Georgia, serif;
$sans-serif: Helvetica, sans-serif;


$link-hover-color: darken(red, 10%);
$darkgray: #585858;
$gray: gray;
$error-color: red;

$subnav-bg-color: #EFEFEF;
$subnav-shadow-color: #A99595;
$input-bg-color: #DFDFDF;
$icon-shadow-color: #3a3a3a;

$nav-height: 55px;
$horizontal-padding: 10px;
$tab-header-height: 60px; // visual height + some bottom margin

$artist-nav-height: 55px;
$slider-nav-width: 40px;

$artist-grid-size: 25%;

$narrow-holder-width: 960px;
$wide-holder-width: 1200px;
$text-holder-width: 740px;

$slider-height: 350px;