#zoomer-wrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;//fix this
  height: 100%;//fix this
  display: none;
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.5);
  overflow: hidden;

  #zoom-close {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    margin: 0;
    h6 {
      position: relative;
      top: -3px;
    }
  }

  #zoomer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;

    #zoom-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 5;
      background-color: rgba(255,255,255,0.5);
      background-image: url(/img/loader3.gif);
      background-repeat: no-repeat;
      background-position: center;
    }

    > img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 4;
      height: auto;
      width: auto;
      max-width: 100%; 
      max-height: 100%;
      visibility: hidden;
      cursor: url(/img/icons/zoom-in.svg), 0;
      //cursor: -webkit-zoom-out;
      //cursor: -moz-zoom-out;
      //cursor: zoom-out;

      &.fill-horizontal {
        max-height: none;
        top: 0%;
        transform: translateX(-50%);
        cursor: url(/img/icons/zoom-out.svg), 0;
        //cursor: -webkit-zoom-out;
        //cursor: -moz-zoom-out;
        //cursor: zoom-out;
      }
      &.fill-vertical {
        max-width: none;
        left: 0%;
        transform: translateY(-50%);
        cursor: url(/img/icons/zoom-out.svg), 0;
        //cursor: -webkit-zoom-out;
        //cursor: -moz-zoom-out;
        //cursor: zoom-out;
      }
    }
  }

  &.active {
    display: block;
  }

  &.loaded {
    #zoomer img {
      visibility: visible;
    }
    #zoom-loader {
      display: none;
    }
  }
}
