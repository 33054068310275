.slideshow {
  width: 100%;
  height: 100%;
  padding: 1rem 0 0 0;
  position: relative;
  #cap {
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    .caption {
      margin: 0 auto;
      width: 100%;
      max-width: 920px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .controls {
    height: 3rem;
    .holder {
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    span {
      font-family: $sans-serif;
      font-weight: 200;
      font-size: 0.9rem;
      display: inline-block;
      vertical-align: middle;
      margin: 0 1rem;

      &:not(#count) {
        cursor: pointer;
      }
    }
    img {
      width: 1.2rem;
      height: 1.2rem;
      display: inline-block;
      vertical-align: top;
      margin-right: 0.25rem;
    }
  }

  .sub-nav {
    height: calc(100% - 7rem); // cap + control height + 1 rem padding
    top: 4rem;
  }

  .img-holder {
    margin: 0rem auto;
    height: 100%;
    height: calc(100% - 6rem); // cap + control height
    width: calc(100% - 100px); // full - nav width
    position: relative;
  }
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;

    & > img {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      cursor: url(/img/icons/expand.svg), 0;
    }

    .caption {
      display: none;
    }

    &.active {
      opacity: 1;
      z-index: 1;
    }
  }
}

.nav#slideshow {
  .icon {
    box-shadow:  0px 0px 2px 0px #3a3a3a;
    display: inline-block;
    position: relative;
  }
  #counter {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    //background-color: #AFAFAF;
    padding: 0 0.35rem;
    width: auto;
    min-width: $artist-nav-height;
    text-align: center;
    height: $artist-nav-height;
    //box-shadow: inset 0 0 4px 0 black;
    h6 {
      margin: 0;
      font-size: 1.75rem;
      line-height: 1;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    sub, sup {
      font-size: 1rem;
      padding: 0 0.1rem;
      font-weight: 400;
    }
    sup {
      top: -1rem;
    }
  }
}
