@media only screen and (max-width: 1070px) {
  .grid {
    .grid-item {
      width: 33.33% - 0.5%;
      padding-top: 0.7*33% - 0.5%;
    }
    .hover h5 {
      font-size: 0.9rem;
    }
  }



  html.exhibitions, html.search {
    .exhibition-info {
      display: block;
      float: none;
      width: 100%;
      height: auto;
      margin-bottom: 0rem;
      text-align: left;
      background-color: transparent;
      box-shadow: none;
      .inner {
        text-align: left;
        top: 0;
        transform: none;
        h6 {
          margin-top: 0.8rem;
        }
        p {
          margin-top: 0.8rem;
          margin-bottom: 0;
        }
      }
    }
    .slider {
      width: 100%;
      float: none;
    }
  } 
  #overlay .exhibition-info .inner {
    text-align: center;
  }
}

@media only screen and (max-width: 960px) {
  html.artists #filter {
    margin-right: 1rem;
    width: 55%;
  }
  html.available-works, html.available-works-slideshow {
    .icon.tab {
      padding: 0.5rem 0.25rem;
      h6 {
        margin: 0 0.25rem;
      }
    }
    #filter {
      width: 45%;
      margin-right: 0.4rem;
    }
  }

}

@media only screen and (max-width: 890px) {
  .slideshow  {
    #cap {
      height: 4rem;
    }
    .sub-nav {
      height: calc(100% - 8rem);
    }
    .img-holder {
      height: calc(100% - 8rem); // cap + control height 
    }
  }
}

@media only screen and (max-width: 850px) {
  .list {
    column-count: 2;
  }
  #breadcrumbs .subtitle {
    display: none;
  }
  html.exhibition .image.grid img {
    max-height: 340px;
  }
  
}