html.exhibitions, html.search {
  #wrapper {
    //padding-top: $top-padding-extra;
  }
  .exhibition {
    width: 100%;
    overflow: auto;
    padding: 2rem 0rem 4rem 0rem;
  }

  .exhibition-info {
    display: inline-block;
    text-decoration: none;
    width: 22%;
    position: relative;
    float: left;
    height: 350px; // matching height of sliders
    padding: 1rem;
    background-color: #EFEFEF;
    box-shadow: 0px 0px 3px 0px #A99595;
    .inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }


  .slider {
    width: 78%;
    position: relative;
    float: left;
    margin-bottom: 0;
    .nav {
      width: .65*$slider-nav-width;
    }
  }


  .pages {
    text-align: center;
    margin-bottom: 6rem;
    margin-top: 2rem;
    position: relative;
    .sub-nav {
      position: relative;
      height: auto;
      display: inline-block;
      width: 25px;
      top: 0.4rem;
      .arrow {
        display: inline-block;
        transform: none;
        top: 0;
        width: 25px;
        height: 25px;
      }
      &:hover, &:focus {
        .arrow { background-position: -25px 0; }
      }
      &.disabled {
        .arrow { background-position: -50px 0; }
      }
    }
  }
  .page.active {
    color: $link-hover-color;
  }


}