@keyframes slideLeft {
  0% { right: -100%;}
  100% { right: 5px;}
}
nav {
  width: 100%;
  height: $nav-height;
  background-color: rgba(255,255,255,1);
  z-index: 6;
  clear: both;
  position: fixed;
  #logo {
    display: block;
    cursor: pointer;
    position: absolute;
    left: 0.8*$horizontal-padding;
    img {
      width: auto;
      height: 1.15*$nav-height;
    }
  }
  .button {
    position: absolute;
    top: 0.8*$horizontal-padding;
    right: 0.8*$horizontal-padding;
    animation: slideLeft 0.5s;
    span {
      margin-right: 5px;
    }
  }
  #breadcrumbs {
    position: absolute;
    left: 70px;
    top: 8px;
    span:not(.subtitle) { 
      display: inline-block;
      vertical-align: middle;
      width: 0.4*$nav-height;
      height: 0.4*$nav-height;
      background-image: url(/img/icons/right.svg);
      background-size: 300%;
      background-position: 0 0;
      background-repeat: none;
    }
    a {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0.3rem;
      top: 1px;
      position: relative;
    }
    img {
      width: 0.4*$nav-height;
      height: 0.4*$nav-height;
      margin: 0;
    }
    h6 {
      display: inline-block;
      &.hidden {
        visibility: hidden;
      }
      &.previous {
        span {
          background-position: -2*0.4*$nav-height 0;
        }
      }
    }
  }
}

html.artist #nav-artists,
html.artists #nav-artists,
html.exhibition #nav-exhibitions,
html.exhibitions #nav-exhibitions,
html.available-works #nav-available-works,
html.available-works-slideshow #nav-available-works,
html.about #nav-about,
html.contact #nav-contact
html.search #nav-contact {
  color: $gray;
}




#overlay {
  position: fixed;
  top: -100%;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  overflow: scroll;
  &.active {
    top: 0;
    right: 0;
    opacity: 1;
  }
  .button {
    position: absolute;
    top: 10px;
    right: 10px;
    span {
      margin-right: 5px;
    }
  }

  .col.right {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-shadow: 2px 0px 14px 0px rgba(0,0,0,0.5);
  }
  .col.left {
    #logo {
      margin: 0 0 2rem 0;
    }
  }
  .exhibition-info {
    background-color: rgba(255,255,255,0.85);
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: auto;
    display: block;
    float: none;
    padding: 1rem;
    margin-bottom: 0;
    padding-top: 2rem;
    .inner {
      transform: none;
      top: auto;
    }
  }
  #close {
    position: absolute;
    z-index: 30;
    padding: 0.2rem 0.25rem;
  }
  ul {
    list-style: none;
    margin: 0 auto;
    padding: none;
    padding-left: 15%;
    top: 45%;
    position: relative;
    transform: translateY(-50%);
    text-align: left;
  }
  li {
    margin: 1rem 0;
  }
  form {
    position: relative;
    margin-top: 3.5rem;
    label {
      height: 100%;
      display: inline;
    }
    input {
      margin: 0;
      margin-right: $horizontal-padding;
      width: 50%;
    }
    input.button {
      position: absolute;
      top: 0px;
      right: auto;
      height: 99%;
      width: auto;
      padding: 0.5rem;
    }
  }

  &.no-ex {
    .right { display: none; }
    .left { width: 100%; }
    ul { 
      text-align: center;
      padding: 0;
    }
    form input[type="text"] {
      width: 30%;
    }
  }
}