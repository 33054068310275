html.contact {
  #wrapper {
    padding-top: $nav-height;
    .holder {
      padding: 0 1rem;
    }
    .col {
      padding: 0 0.5rem;
    }
    input, textarea {
      display: block;
      width: 100%;
      border-radius: 0;
    }

    form {
      padding-bottom: 2rem;
    }
  }
}

html.about {
  #wrapper {
    padding-top: $nav-height;
  }
  .holder {
    margin: 3rem auto 4rem;
  }
}

html.search {
  height: 100%;
  overflow: hidden;
  body, #wrapper {
    height: 100%;
  }

  .nav {
    input:not(.button) {
      height: $artist-nav-height;
      width: 60%;
    }
    input.button {
      margin: 0;
      height: $artist-nav-height;
      margin-left: $horizontal-padding;
      background-color: $subnav-bg-color;
    }
  }

  #search-results {
    height: calc(100% - #{$artist-nav-height});
    position: relative;
    width: 100%;
    overflow: scroll;
  }

  form {
    position: relative;
  }
  input.button {
    position: absolute;
    height: 100%;
  }
}

