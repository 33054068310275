@media only screen and (orientation: portrait),
only screen and (max-height: 600px) {

  .col {
    width: 100%;
    float: none;
    display: block;
  }

  html.contact #wrapper .col {
    padding:  1rem 3rem;
  }

  #overlay {
    .col {
      height: auto;
    }
    a.col.right {
      display: block;
      height: 100%;
    }
    ul {
      text-align: center;
      padding: 0;
      padding-top: 80px;
      padding-bottom: 80px;
      transform: none;
    }
  }

  #overlay.no-ex {
    a.col.right {
      display: none;
    }
  }

  .parallax {
    height: 60%;
  }
}