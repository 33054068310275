.slider {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  
  .sub-nav {
    height: $slider-height;
    width: $slider-nav-width;
    z-index: 1;
    .arrow {
      width: $slider-nav-width;
      height: $slider-nav-width;
    }
    &:hover, &:focus {
      .arrow { background-position: -1*$slider-nav-width 0; }
    }
    &.disabled {
      .arrow { background-position: -2*$slider-nav-width 0; }
    }
  }

  .item-scroller {
    position: relative;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    white-space: nowrap;
    text-align: center;
    transform: translateZ(0);
  }

  .item-wrapper {
    width: auto;
    display: inline-block;
    vertical-align: top;
  }

  .item {
    display: inline-block;
    vertical-align: top;
    .info {
      margin: 0 auto;
      position: relative;
    }
    a.link {
      display: block;
    }
    img {
      width: auto;
      height: $slider-height;
    }
  }

  &.loaded {
    opacity: 1;
    .sub-nav {
      &.visible { visibility: visible; }
      &.hidden { display: none; }
    }
  }
}

.slider-helper {
  margin: 0;
  margin-bottom: 0.2rem;
  padding: 0;
  font-size: 0.9rem;
  text-align: center;
}
