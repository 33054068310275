

@media only screen and (max-width: 750px) {
  .grid {
    .grid-item {
      width: 50% - 0.5%;
      padding-top: 0.7*50% - 0.5%;
    }
  }
  html.artist {
    .text.holder.centered {
      text-align: left;
    }
  }
  html.exhibition .image.grid img {
    max-height: 300px;
  }

}

@media only screen and (max-width: 690px) {
  .nav span.label {
    display: none;
  }
  html.exhibition .image.grid img {
    max-height: 285px;
  }
}

@media only screen and (max-width: 650px) {
  #breadcrumbs {
    display: none;
  }
  html.exhibition .image.grid img {
    max-height: 260px;
  }
}

@media only screen and (max-width: 580px) {
  .tab h5 {
    font-size: 0.8rem;
  }
  html.available-works, html.available-works-slideshow {
    .icon.tab h6 {
      font-size: 0.7rem;
    }
    .nav .holder.narrow {
      padding: 0;
    }
    #filter {
      width: 38%;
    }
  }
  html.artists {
    .nav .holder.narrow {
      padding: 0;
    }
  }
  html.exhibition .image.grid img {
    max-height: 230px;
  }
  .slideshow  {
    #cap {
      height: 5rem;
    }
    .sub-nav {
      height: calc(100% - 9rem);
    }
    .img-holder {
      height: calc(100% - 8rem); // cap + control height 
    }
    .info.inline p {
      line-height: 1.8;
    }
  }
}


@media only screen and (max-width: 480px) {
  html {
    font-size: 14px;
  }

  .grid {
    .grid-item {
      width: 100% - 0.5%;
      padding-top: 0.7*100% - 0.5%;
    }
  }
  .list {
    column-count: 1;
  }
  html.artist h1 {
    font-size: 2.3rem;
  }
  html.exhibition .image.grid img {
    max-height: 190px;
  }
}



@media only screen and (max-width: 420px) {
  .tab h5 {
    font-size: 0.8rem;
  }
  .line {
    top: -7px;
  }
  html.available-works, 
  html.available-works-slideshow,
  html.artists {
    #filter {
      display: none;
    }
  }
  html.contact #wrapper .col {
    padding: 1rem;
  }
  html.exhibition .image.grid img {
    max-height: 160px;
  }
  .slider .item img {
    width: auto;
    height: 250px;
  }
}

@media only screen and (max-width: 380px) {
  html {
    font-size: 12px;
  }
  html.exhibition .image.grid img {
    max-height: 130px;
  }
}