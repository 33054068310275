html.collection-slideshow {
  height: 100%;
  overflow: hidden;
  body {
    height: 100%;
  }
  #wrapper {
    height: 100%;
  }

  // #filter {
  //   height: $artist-nav-height;
  //   width: 52%;
  //   display: inline-block;
  //   vertical-align: middle;
  //   margin-right: 1rem;
  //   line-height: 1;
  // }
  // .tabs {
  //   width: 25%;
  //   position: relative;
  //   top: 0;
  // }
  // .icon.tab {
  //   width: auto;
  //   padding: 0.5rem;
  //   text-decoration: none;
  //   img {
  //     display: inline-block;
  //     height: 100%;
  //     width: auto;
  //     vertical-align: middle;
  //   }
  //   h6 {
  //     display: inline-block;
  //     text-align: left;
  //     vertical-align: middle;
  //   }
  // }

  .slideshow {
    height: 100%;//calc(100% - #{$nav-height});
    padding-top: 0;
  }
  
}