html.homepage {
  height: 100%;

  body, #wrapper {
    height: 100%;
  }

  nav {
    background-color: rgba(255,255,255,0.7);
  }

  .bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 0;
    transition: opacity 0.6s ease-in;
    &.active {
      opacity: 1;
      z-index: 1;
    }
  }
}