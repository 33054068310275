form {
  position: relative;

  p.error {
    display: none;
    color: $error-color;
    margin: 0.5rem 0;
    &.visible {
      display: block;
    }
  }

  label {
    span {
      font-family: $sans-serif;
      font-weight: 200;
      font-size: 0.9rem;
    }
    display: block;
    margin: 1.2rem 0 0.25rem 0;
  }

  input.error, textarea.error {
    box-shadow: inset 0 0 0 1000px lighten($error-color, 40%);
    &:invalid {
      border: 2px solid red;
    }
  }

  input.button {
    margin-top: 2rem;
    border-radius: 0;
    padding: 1rem;
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0 0 10px -4px rgba(0,0,0,0.6);
    z-index: 2;
    display: none;
    p { 
      display: none; 
      text-align: center;
      position: relative;
      top: 40%;
      font-size: 2rem;
      transform: translateY(-50%);
    }
    &.loading {
      display: block;
      .loader {display: block; }
    }
    &.success {
      display: block;
      .sent {
        display: block; 
        color: green;
      }
    }
  }
}

input, textarea {
  font-weight: 100;
  border: 0;
  font-size: 1.6rem;
  padding: 0.5rem;
  background-color: $input-bg-color;
  box-shadow: inset 0 0 0 1000px $input-bg-color;
  text-shadow: 0px 0px 0px #000;
  text-fill-color: transparent;
  appearance: none;
  -webkit-text-fill-color: transparent;
  -webkit-appearance: none;
  color: $link-hover-color;
  &::-webkit-input-placeholder {
    color: $darkgray;
    text-shadow: none;
    text-fill-color: initial;
    -webkit-text-fill-color: initial;
  }
  &:not(.button):focus {
    outline: none;
    border: none;
    border-bottom: 2px solid $link-hover-color;
  }
}

textarea {
  height: 200px;
}

