html.exhibition {
  height: 100%;
  body, #wrapper, .tab-content {
    height: 100%;
  }
  .slideshow {
    padding-top: 0;
  }
  .tabs-content {
    height: calc(100% - (#{$tab-header-height} + 4px)); // 4px box shadow
    &.scroll {
      overflow: scroll;  
    }
  }
  #about {
    margin-top: 2.5rem;
  }
  .holder.narrow {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }
  .holder#view-more {
    text-align: center;
  }
  .exhibition-info {
    p {
      margin-top: 1.3rem;
    }
  }
  .image {
    margin: 3rem 0;
  }
  .image.single {
    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }
  .image.grid {
    width: 100%;
    text-align: center;
    img {
      position: relative;
      max-height: 400px;
      width: auto;
      margin: 0 0.5rem;
      display: inline-block;
    }
  }
  .button-wrapper {
    margin-bottom: 6rem;
  }
}